import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../layouts/Box"
import Heading from "../atoms/Heading"

const Addresses = () => {
  const data = useStaticQuery(graphql`
    query ShopQuery {
      allSanityShop {
        edges {
          node {
            id
            title
            address {
              street
              number
              zip
              city
            }
          }
        }
      }
    }
  `)

  const results = data.allSanityShop.edges || []

  return (
    <Box
      display="grid"
      gridGap={[3, 5]}
      gridTemplateColumns={[`1fr`, `1fr 1fr`]}
    >
      {results.map(({ node }) => (
        <Box key={node.id}>
          <Heading as="h6" mb="2">
            {node.title}
          </Heading>
          <Box as="p" m="0">
            {node.address.street} {node.address.number}
            <br />
            {node.address.zip} {node.address.city}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default Addresses
