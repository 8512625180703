import React from "react"

import styled from "styled-components"
import {
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  typography,
} from "styled-system"

const StyledBox = styled.div`
  ${border}
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${position}
  ${space}
  ${typography}
`

const Box = props => {
  return <StyledBox {...props}>{props.children}</StyledBox>
}

export default Box
