import React from "react"

import { IconContext } from "react-icons"
import { FaFacebookSquare, FaInstagram } from "react-icons/fa"
import styled, { useTheme } from "styled-components"

const StyledLink = styled.a`
  border: 0;
  display: inline-block;
  margin-right: ${props => props.theme.space[3]};

  &:hover {
    opacity: 0.75;
  }
`

const SocialIcon = ({ icon }) => {
  const theme = useTheme()
  const icons = [
    {
      id: "facebook",
      color: "#1977f2",
      url: "https://www.facebook.com/Bloemenhal",
    },
    {
      id: "instagram",
      color: "#DD2A7B",
      url: "https://www.instagram.com/bloemenhal.nv/",
    },
  ]

  const [mappedIcon] = icons.filter(el => el.id === icon)
  const style = { fontSize: theme.fontSizes[4], color: mappedIcon.color }

  return (
    <StyledLink href={mappedIcon.url}>
      <IconContext.Provider value={{ style: style }}>
        {mappedIcon.id === "facebook" && <FaFacebookSquare />}
        {mappedIcon.id === "instagram" && <FaInstagram />}
      </IconContext.Provider>
    </StyledLink>
  )
}

export default SocialIcon
