import React from "react"
import { useSiteMetadata } from "../../hooks/use-site-metadata"

import Wrapper from "../layouts/Wrapper"
import Grid from "../layouts/Grid"
import Box from "../layouts/Box"

const Topbar = () => {
  const { company } = useSiteMetadata()

  return (
    <Wrapper bg="brand.primary" py={2}>
      <Grid
        gridTemplateColumns={[`auto auto`]}
        alignItems="center"
        color="cream"
        fontSize={1}
      >
        <span>{company.email}</span>
        {/* <Box as="span" textAlign={["right"]}>
          Bestellen vanaf € 25,00
        </Box> */}
      </Grid>
    </Wrapper>
  )
}

export default Topbar
