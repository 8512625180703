import React from "react"

import styled from "styled-components"
import { background } from "styled-system"

import { useSiteMetadata } from "../../hooks/use-site-metadata"

import Heading from "../atoms/Heading"
import Box from "../layouts/Box"
import Grid from "../layouts/Grid"
import Wrapper from "../layouts/Wrapper"
import LegalNav from "../molecules/LegalNav"
import SocialIcon from "../atoms/SocialIcon"
import Addresses from "../molecules/Addresses"

const StyledFooter = styled(Wrapper)`
  ${background}
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
`

const Footer = () => {
  const { company } = useSiteMetadata()

  return (
    <>
      <StyledFooter
        backgroundImage={[
          "url(/images/green-succulent-plants-on-pots-25.png)",
          "url(/images/green-succulent-plants-on-pots-100.png)",
        ]}
        as="footer"
        py={8}
        bg="cream"
      >
        <Grid gridRowGap={[5]}>
          <Box gridColumn={["span 4", "span 6", "span 9"]}>
            <Heading as="h5">Onze winkels</Heading>
            <Addresses />
          </Box>

          <Box gridColumn={["span 4", "span 2", "span 3"]}>
            <Heading as="h5">Volg ons op</Heading>
            <SocialIcon icon="facebook" />
            <SocialIcon icon="instagram" />
          </Box>
        </Grid>
      </StyledFooter>

      <Wrapper my={6} fontSize="1">
        <Grid gridRowGap={3}>
          <Box gridColumn={["span 4", "span 3"]}>
            © {new Date().getFullYear()} {company.name}
          </Box>
          <LegalNav gridColumn={["span 4", "span 8", "span 9"]} />
        </Grid>
      </Wrapper>
    </>
  )
}

export default Footer
