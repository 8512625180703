import styled from "styled-components"
import {
  color,
  fontSize,
  flexbox,
  layout,
  space,
  typography,
} from "styled-system"

const getFontFamily = (size, { theme }) => {
  if (size === "h1" || size === "h2") {
    return theme.font.heading
  } else {
    return theme.font.body
  }
}

const getMarginBottom = (size, { theme }) => {
  let space
  switch (size) {
    case "h1":
      space = theme.space[6]
      break

    case "h2":
      space = theme.space[5]
      break

    default:
      space = theme.space[4]
      break
  }
  return space
}

const Heading = styled.div`
  font-family: ${props => getFontFamily(props.as, props)};
  font-size: ${props => props.theme.fontSizes[props.as]};
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 ${props => getMarginBottom(props.as, props)};

  ${color};
  ${flexbox};
  ${fontSize};
  ${layout};
  ${space};
  ${typography};
`

export default Heading
