import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import styled from "styled-components"
import { flexbox, space, layout, typography } from "styled-system"

import { FaHome } from "react-icons/fa"
import { createSlug } from "../../utils/helpers"

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;

  ${flexbox}
  ${layout}
  ${space}
  ${typography}
`

const StyledLink = styled(Link)`
  font-weight: 600;
  text-transform: uppercase;

  &.active {
    color: ${props => props.theme.colors.brand.secondary};
  }
`

const NavList = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCategory {
        edges {
          node {
            id
            title
            show
          }
        }
      }
    }
  `)

  const categories = data.allSanityCategory.edges.filter(({ node }) => node.show)

  return (
    <StyledList
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      fontSize={[1]}
    >
      <li>
        <StyledLink to="/">
          <FaHome />
        </StyledLink>
      </li>
      {categories.map(({ node }) => {
        return (
          <li key={node.id}>
            <StyledLink
              to={`/${createSlug(node.title)}`}
              activeClassName="active"
            >
              {node.title}
            </StyledLink>
          </li>
        )
      })}
    </StyledList>
  )
}

export default NavList
