const slugify = require("slugify")

exports.createSlug = props => {
  return slugify(props, { lower: true })
}

exports.createCategorySlug = (cat, product) => {
  const slugGenerator = {
    cat: this.createSlug(cat),
    product: product,
  }
  return `${slugGenerator.cat}/${slugGenerator.product}`
}

exports.priceFormatOpts = {
  separator: ".",
  decimal: ",",
  symbol: "€ ",
}
