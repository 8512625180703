import React from "react"

import Grid from "../layouts/Grid"
import Wrapper from "../layouts/Wrapper"
import Box from "../layouts/Box"

import Logo from "../atoms/Logo"
import Cart from "../atoms/Cart"
import NavList from "../molecules/NavList"

const Header = props => {
  return (
    <Wrapper
      as="header"
      bg="white"
      boxShadow={["2px 2px 8px rgba(0, 0, 0, .2)"]}
      position={["sticky"]}
      py={[2, 3, 4]}
      top={[0]}
      zIndex={[1]}
    >
      <Grid alignItems="center">
        <Box gridColumn={["span 2"]}>
          <Logo />
        </Box>
        {/* <Box
          alignSelf="center"
          gridColumn={["span 2", "span 6", "span 10"]}
          justifySelf={["flex-end"]}
        >
          <Cart />
        </Box> */}
        {/* <Box
          as="nav"
          gridColumn={[null, null, "span 12"]}
          display={["none", "none", "grid"]}
          mt={4}
        >
          <NavList />
        </Box> */}
      </Grid>
    </Wrapper>
  )
}

export default Header
