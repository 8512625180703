import React from "react"
import styled, { useTheme } from "styled-components"
import { color, layout, position, space, typography } from "styled-system"

import Box from "../layouts/Box"

import { IconContext } from "react-icons"
import { FaShoppingBag } from "react-icons/fa"

import currency from "currency.js"

const StyledCart = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  outline: 0;

  ${color}
  ${layout}
  ${position}
  ${space}
`

const StyledCounter = styled.span`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: ${props => props.theme.space[4]};
  justify-content: center;
  width: ${props => props.theme.space[4]};

  ${color}
  ${typography}
  ${position}
`

const calculateQuantity = items => {
  let quantity = 0

  items.length && items.forEach(item => {
    quantity += item.quantity
  })

  return quantity
}

const Cart = () => {
  const theme = useTheme()
  let snipcart = null

  if (typeof window !== `undefined`) {
    snipcart = window.Snipcart.store.getState()
  }

  return (
    <StyledCart className="snipcart-checkout">
      <Box position="relative" mr={[0, 3]}>
        <IconContext.Provider
          value={{
            style: {
              color: theme.colors.brand.secondary,
              fontSize: theme.fontSizes[4],
            },
          }}
        >
          <FaShoppingBag />
        </IconContext.Provider>

        <StyledCounter
          bg="brand.primary"
          color="white"
          fontSize={1}
          position="absolute"
          top="-25%"
          right="-25%"
          className="snipcart-items-count"
        >
          {typeof window !== `undefined` &&
            calculateQuantity(snipcart.cart.items)}
        </StyledCounter>
      </Box>
      <Box
        as="span"
        className="snipcart-total-price"
        color="brand.primary"
        display={["none", "block"]}
        fontSize={3}
      >
        {typeof window !== `undefined` &&
          currency(snipcart.cart.total, {
            separator: ".",
            decimal: ",",
            symbol: "€ ",
          }).format(true)}
      </Box>
    </StyledCart>
  )
}

export default Cart
