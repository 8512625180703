import React from "react"

import Box from "../layouts/Box"
import Heading from "../atoms/Heading"
import { FaHandsHelping } from "react-icons/fa"

const Shop = props => {
  return (
    <Box bg="mint" p={5} {...props}>
      <Heading as="h4" fontSize="h5" alignItems="center" display="flex">
        <FaHandsHelping />{" "}
        <Box as="span" ml={4}>
          Wil je graag advies?
        </Box>
      </Heading>
      <Box as="p">
        Kom gerust langs in één van onze winkels in <b>Sint-Truiden</b>,{" "}
        <b>Alken</b>, <b>Tongeren</b> of <b>Luik</b> of stuur een mailtje naar{" "}
        <a href="mailto:info@bloemenhal.com">info@bloemenhal.com</a> .
      </Box>
    </Box>
  )
}

export default Shop
