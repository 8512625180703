import styled from "styled-components"
import {
  background,
  color,
  fontSize,
  layout,
  position,
  shadow,
  space,
} from "styled-system"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  ${background}
  ${color}
  ${fontSize}
  ${position}
  ${layout}
  ${shadow}
  ${space}
`

export default Wrapper
