import React from "react"

import styled from "styled-components"
import { color, flexbox, grid, space, typography } from "styled-system"

export const StyledGrid = styled.div`
  display: grid;
  max-width: 112.5rem;
  width: 100%;

  ${color}
  ${flexbox}
  ${grid}
  ${space}
  ${typography}
`

const Grid = props => {
  return (
    <StyledGrid
      gridTemplateColumns={[
        "repeat(4, 1fr)",
        "repeat(8, 1fr)",
        "repeat(12, 1fr)",
      ]}
      gridColumnGap={5}
      mx={[3, 5]}
      {...props}
    >
      {props.children}
    </StyledGrid>
  )
}

export default Grid
