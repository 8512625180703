import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    font-size: 62.5%;
    min-height: 100%;
  }

  body {
    color: ${props => props.theme.colors.dark};
    font-family: ${props => props.theme.font.body};
    font-weight: 300;
    font-size: ${props => props.theme.fontSizes[2]};
    line-height: 1.75;
    overflow-x: hidden;
  }

  h1, h2 {
    color: ${props => props.theme.colors.brand.secondary};
    font-family: ${props => props.theme.font.heading};
  }
  
  h3, h4, h5, h6 {
    color: ${props => props.theme.colors.brand.primary};
    font-family: ${props => props.theme.font.body};
  }

  a {
    border-bottom: 1px solid ${props => props.theme.colors.brand.secondary};
    color: ${props => props.theme.colors.brand.primary};
    text-decoration: none;
    transition: color .1s linear,border-color .1s linear;

    &:hover {
      border-color: ${props => props.theme.colors.brand.primary};
    }
  }

  b, strong {
    font-weight: 700;
  }
`

export default GlobalStyle
