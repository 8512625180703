const theme = {
  breakpoints: ["768px", "1024px", "1125px"],
  colors: {
    white: "#FFF",
    gray: "#E8E8E8",
    brand: {
      primary: "#006739",
      secondary: "#38b549",
    },
    accent: "#EA4f1C",
    dark: "#031E12",
    mint: "#D7F2E6",
    cream: "#F9FFFC",
  },
  font: {
    body: "'Poppins', Arial,  Helvetica, sans-serif",
    heading: "'Playfair Display', Georgia, serif",
  },
  fontSizes: [
    "1.2rem",
    "1.4rem",
    "1.6rem",
    "2.4rem",
    "3.2rem",
    "4.0rem",
    "4.8rem",
    "5.6rem",
    "6.4rem",
  ],
  space: [
    0,
    ".4rem",
    ".8rem",
    "1.6rem",
    "2.4rem",
    "3.2rem",
    "4rem",
    "4.8rem",
    "5.6rem",
    "6.4rem",
    "8rem",
  ],
}

theme.fontSizes.h1 = theme.fontSizes[8]
theme.fontSizes.h2 = theme.fontSizes[7]
theme.fontSizes.h3 = theme.fontSizes[6]
theme.fontSizes.h4 = theme.fontSizes[5]
theme.fontSizes.h5 = theme.fontSizes[3]
theme.fontSizes.h6 = theme.fontSizes[2]

export default theme
