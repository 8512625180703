import React from "react"
import { Link } from "gatsby"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import styled from "styled-components"
import Box from "../layouts/Box"

const StyledLink = styled(Link)`
  border: 0;
`

const Logo = () => {
  const { title } = useSiteMetadata()

  return (
    <StyledLink to="/">
      <Box
        as="img"
        width={["100%"]}
        src={"/images/bloemenhal.png"}
        alt={title}
      />
    </StyledLink>
  )
}

export default Logo
