import React from "react"

import { ThemeProvider } from "styled-components"
import GlobalStyle from "../../styles/global"
import theme from "../../styles/theme"

import Box from "./Box"
import Grid from "./Grid"
import Wrapper from "./Wrapper"

import Header from "../organismes/Header"
import Footer from "./../organismes/Footer"

import Shop from "../molecules/Shop"
import Tip from "../molecules/Tip"

import Topbar from "../organismes/Topbar"
import Hero from "../molecules/Hero"

const Layout = props => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <Box display="flex" minHeight="100vh" flexDirection="column">
          <Topbar />
          <Header siteTitle={`Bloemenhal`} />
          {/* {props.showHero && <Hero />} */}

          <Box as="main" role="main" flex="1" my={9}>
            <Wrapper>{props.children}</Wrapper>
          </Box>

          <Wrapper my={9}>
            <Grid gridTemplateColumns={["1fr"]} gridRowGap={3}>
              {/* <Tip /> */}
              <Shop />
            </Grid>
          </Wrapper>

          <Footer />
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Layout
