import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import styled from "styled-components"
import { layout } from "styled-system"

import Box from "../layouts/Box"

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${layout}
`

const LegalNav = props => {
  const data = useStaticQuery(graphql`
    query LegalPagesQuery {
      allMdx(
        sort: { order: ASC, fields: frontmatter___title }
        filter: { frontmatter: { collection: { eq: "legal" } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)

  const pages = data.allMdx.edges

  return (
    <>
      <Box
        as="nav"
        alignItems={["left"]}
        display={["block", "flex"]}
        justifyContent={["flex-start", "flex-start", "flex-end"]}
        {...props}
      >
        <StyledList display={["block", "flex"]}>
          {pages.map(({ node }) => (
            <Box key={node.id} as="li" mr={[0, 4, 0]} ml={[0, 0, 4]}>
              <Link key={node.id} to={node.fields.slug}>
                {node.frontmatter.title}
              </Link>
            </Box>
          ))}
        </StyledList>
      </Box>
    </>
  )
}

export default LegalNav
